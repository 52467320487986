import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import SearchIcon from "@mui/icons-material/Search";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import { getDaysAgo, handleError } from "../../utils/common";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { CheckCircle, PhoneAndroid, QrCode } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Merchantsv1 from "./indexv1";

const Merchants = () => {
  const [inputValue, setInputValue] = useState("");
  const [searchBy, setSearchBy] = useState("whatsapp_number");
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [providersData, setProvidersData] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const lsRecentMerchants = localStorage.getItem("rec_merch")
    ? JSON.parse(String(localStorage.getItem("rec_merch")))
    : [];
  const theme = useTheme();
  const [version, setVersion] = useState("v2");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getProviderData = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/providers?filter=${searchBy}&value=${inputValue}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setProvidersData(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">All Pharmacies</Typography>
        <FormControl sx={{ minWidth: "120px" }} size="small">
          <InputLabel id="version-label">UI Version</InputLabel>
          <Select
            labelId="version-label"
            name="version"
            id="version"
            label="UI Version"
            value={version}
            onChange={(e) => setVersion(e.target.value)}
          >
            <MenuItem value="v1">Old</MenuItem>
            <MenuItem value="v2">New</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {version === "v2" ? (
        <>
          <Box
            sx={{
              p: 3,
              my: 3,
              backgroundColor: "#E6EEFF",
              borderRadius: 3,
            }}
          >
            <Typography variant="h6">Search for Pharmacy</Typography>
            <Stack
              mt={3}
              direction={{ xs: "column", md: "row" }}
              alignItems="center"
              gap={{ xs: 1.5, md: 3 }}
            >
              <FormControl
                disabled={loading}
                size="small"
                sx={{ minWidth: "220px" }}
              >
                <Select
                  labelId="search-by-label"
                  name="search_by"
                  id="search_by"
                  value={searchBy}
                  onChange={(e: SelectChangeEvent) => {
                    setSearchBy(e.target.value);
                  }}
                  displayEmpty
                  sx={{ backgroundColor: "#FFF", borderRadius: 1.5 }}
                >
                  <MenuItem value="gst_no">Gst Number</MenuItem>
                  <MenuItem value="location_id">Location Id</MenuItem>
                  <MenuItem value="whatsapp_number">Whatsapp Number</MenuItem>
                </Select>
              </FormControl>
              <Typography color="secondary">Is</Typography>
              <TextField
                disabled={loading}
                id="value"
                size="small"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                InputLabelProps={{
                  required: true,
                }}
                sx={{
                  backgroundColor: "#FFF",
                  minWidth: "220px",
                  borderRadius: 1.5,
                }}
              />
              <LoadingButton
                size="medium"
                color="primary"
                loading={loading}
                loadingIndicator={
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                }
                variant="contained"
                type="button"
                onClick={getProviderData}
                disabled={!inputValue?.trim()}
                startIcon={<SearchIcon />}
              >
                Search
              </LoadingButton>
            </Stack>
          </Box>
          {providersData?.length ? (
            <>
              <Grid container spacing={2}>
                {providersData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: number) => {
                    return (
                      <Grid key={index} item xs={12} md={6}>
                        <Box
                          sx={{
                            border: "1px solid #EAEBEE",
                            borderRadius: 1.5,
                          }}
                        >
                          <Stack gap={1} p={2}>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography
                                variant="h6"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {row.provider_name}
                              </Typography>
                              <Box display="flex" alignItems="center" gap={1}>
                                <Chip
                                  size="small"
                                  label={row.status}
                                  sx={{
                                    textTransform: "capitalize",
                                    backgroundColor:
                                      row.status === "pending"
                                        ? "#FBD38D"
                                        : row.status === "active"
                                        ? "#C6F6D5"
                                        : "#C53030",
                                    color: ["pending", "active"].includes(
                                      row.status
                                    )
                                      ? ""
                                      : "#FFF",
                                  }}
                                />
                                <IconButton
                                  size="small"
                                  color="primary"
                                  sx={{ rotate: "45deg" }}
                                  onClick={() => {
                                    let existingData = [...lsRecentMerchants];
                                    //remove element if already present
                                    existingData = existingData.filter(
                                      (x) => x.locId !== row.location_id
                                    );
                                    //remove last element if limit reached
                                    if (existingData.length >= 4) {
                                      existingData = existingData.slice(0, -1);
                                    }
                                    //push new element at start
                                    existingData.unshift({
                                      name: row.provider_name,
                                      locId: row.location_id,
                                    });
                                    //update ls
                                    localStorage.setItem(
                                      "rec_merch",
                                      JSON.stringify(existingData)
                                    );
                                    //redirect
                                    navigate(`/merchants/${row.location_id}`);
                                  }}
                                >
                                  <UnfoldMoreIcon />
                                </IconButton>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="body2" color="secondary">
                                +91 {row.whatsapp_number}
                              </Typography>
                              <Typography variant="body2">
                                {row.city}, {row.state} - {row.pin_code}
                              </Typography>
                            </Box>
                          </Stack>
                          <Box
                            sx={{
                              backgroundColor: "#F0F1F5",
                              borderBottomLeftRadius: 3,
                              borderBottomRightRadius: 3,
                              p: 2,
                              minHeight: "3.5rem",
                            }}
                          >
                            <Box display="flex" justifyContent="space-between">
                              <Box display="flex" alignItems="center" gap={2}>
                                {row.static_qr_enabled ? (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    gap={0.75}
                                    sx={{
                                      backgroundColor: "#fff",
                                      py: 0.75,
                                      px: 2,
                                      borderRadius: 10,
                                    }}
                                  >
                                    <QrCode
                                      fontSize="small"
                                      color="secondary"
                                    />
                                    <Typography
                                      variant="body2"
                                      color="secondary"
                                    >
                                      QR Active
                                    </Typography>
                                  </Box>
                                ) : null}
                                {row.medpay_store_enabled ? (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    gap={0.75}
                                    sx={{
                                      backgroundColor: "#fff",
                                      py: 0.75,
                                      px: 2,
                                      borderRadius: 10,
                                    }}
                                  >
                                    <CheckCircle
                                      fontSize="small"
                                      color="secondary"
                                    />
                                    <Typography
                                      variant="body2"
                                      color="secondary"
                                    >
                                      Online Store
                                    </Typography>
                                  </Box>
                                ) : null}
                                {/* <Box
                              sx={{
                                backgroundColor: "#fff",
                                py: 0.75,
                                px: 2,
                                borderRadius: 10,
                              }}
                            >
                              <Typography variant="body2" color="secondary">
                                Soundbox &nbsp;|&nbsp; <strong>CWD</strong>
                              </Typography>
                            </Box> */}
                              </Box>
                              {row.last_app_active_at ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gap={0.5}
                                >
                                  <Tooltip title="Last App Active:">
                                    <PhoneAndroid color="secondary" />
                                  </Tooltip>
                                  <Typography
                                    variant="body2"
                                    sx={{ color: "#33415C" }}
                                  >
                                    {getDaysAgo(row.last_app_active_at)}
                                  </Typography>
                                </Box>
                              ) : null}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
              </Grid>
              <Box
                mt={1.5}
                sx={{
                  border: "1px solid #EAEBEE",
                  // alignItems: "start",
                  // display: "flex",
                }}
              >
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 40]}
                  component="div"
                  count={providersData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="Cards per page"
                />
              </Box>
            </>
          ) : null}
          {lsRecentMerchants?.length ? (
            <Box mt={3}>
              <Typography mb={1} variant="h5">
                Recent Searches
              </Typography>
              <Grid container spacing={1.5}>
                {lsRecentMerchants.map((x: any) => (
                  <Grid item xs={6} md={3}>
                    <Box
                      sx={{
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                      }}
                      onClick={() => navigate(`/merchants/${x.locId}`)}
                    >
                      <Box
                        sx={{
                          border: "1px solid #EAEBEE",
                          p: 1.5,
                          borderRadius: 3,
                          "&:hover": { backgroundColor: "#EAEBEE" },
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            textTransform: "capitalize",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {x.name}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : null}
        </>
      ) : (
        <Box mt={1.5}>
          <Merchantsv1 />
        </Box>
      )}
    </>
  );
};

export default Merchants;
