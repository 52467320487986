import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import DateTimeSelect from "../../../components/Common/dateTimeSelect";
import { degreeOptions, genders, valueLimits } from "../../../constants";
import {
  textCapitalize,
  validateFloatNum,
  validateIntNum,
} from "../../../utils/common";
import DiscountPercent from "../../../components/ManageOrder/GroupOrders/Common/discountPercent";
import OrderDoctorInfo from "../../Settings/Orders/orderDoctorInfo";
import ClearIcon from "@mui/icons-material/Clear";
import SearchNearby from "../../../components/ManageOrder/GroupOrders/Common/NearbyProviders/searchNearby";

const EditConsultationOrder = ({
  setFieldValue,
  touched,
  errors,
  handleBlur,
  selectedClinics,
  setSelectedClinics,
  nearbyClinicList,
  setNearbyClinicList,
  orderData,
  formData,
  setChangedClinic,
}: any) => {
  const {
    speciality,
    time_slot,
    doctor_name,
    doctor_gender,
    doctor_degrees,
    doctor_exp_yrs,
    doctor_mobile,
    doctor_fee,
    discount,
    final_payable,
    partner_charges,
    allow_profile_update,
  } = formData;
  return (
    <>
      <TextField
        fullWidth
        id="speciality"
        label="Speciality"
        size="small"
        value={textCapitalize(speciality) || ""}
        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        InputLabelProps={{
          required: true,
        }}
        disabled
      />
      <Box sx={{ mt: 3 }}>
        {selectedClinics.length > 0 ? (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Stack>
              <OrderDoctorInfo
                doctor={selectedClinics[0]}
                time_slot={orderData?.time_slot}
                time_slot_label="Requested appointment time"
                showLink={false}
              />
            </Stack>
            <Tooltip title="choose different doctor">
              <Button
                color="error"
                variant="contained"
                size="small"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure, you want to choose a different doctor?"
                    )
                  ) {
                    setSelectedClinics([]);
                    setChangedClinic(true);
                  }
                }}
              >
                <ClearIcon />
              </Button>
            </Tooltip>
          </Stack>
        ) : (
          <SearchNearby
            autocompleteLabel="Search provider"
            nearbyProviders={nearbyClinicList}
            setNearbyProviders={setNearbyClinicList}
            orderData={orderData}
            selectedProviders={selectedClinics}
            setSelectedProviders={setSelectedClinics}
            showFetch={false}
            assignLoading={false}
          />
        )}
      </Box>
      <Box sx={{ mt: 3 }}>
        <DateTimeSelect
          time_slot={time_slot}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          pickerLabel="Appointment Date & Time"
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4">Doctor Details:</Typography>
        <Stack direction={{ xs: "column", md: "row" }}>
          <TextField
            fullWidth
            id="doctor_name"
            label="Doctor Name"
            size="small"
            value={doctor_name || ""}
            onChange={(e) => setFieldValue("doctor_name", e.target.value)}
            onBlur={handleBlur}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            helperText={
              errors.doctor_name && touched.doctor_name
                ? errors.doctor_name
                : ""
            }
            error={errors.doctor_name && touched.doctor_name ? true : false}
            inputProps={{
              maxLength: valueLimits.NAME_LEN,
            }}
            InputLabelProps={{
              required: true,
            }}
          />
          <FormControl
            size="small"
            fullWidth
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            error={errors.doctor_gender && touched.doctor_gender ? true : false}
          >
            <InputLabel id="doctor-gender-label">Gender</InputLabel>
            <Select
              labelId="doctor-gender-label"
              name="doctor_gender"
              id="doctor_gender"
              value={doctor_gender || ""}
              label="Gender"
              onChange={(e: SelectChangeEvent) => {
                setFieldValue("doctor_gender", e.target.value);
              }}
              onBlur={handleBlur}
            >
              {Object.entries(genders).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
            {errors.doctor_gender && touched.doctor_gender && (
              <FormHelperText>{errors.doctor_gender}</FormHelperText>
            )}
          </FormControl>
          <Autocomplete
            multiple
            fullWidth
            size="small"
            id="doctor_degrees"
            options={degreeOptions}
            value={doctor_degrees}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Degree"
                name="doctor_degrees"
                placeholder="Select..."
              />
            )}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            onChange={(e, val: any) => {
              setFieldValue("doctor_degrees", val);
            }}
            getOptionLabel={(option) => option}
          />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }}>
          <TextField
            fullWidth
            id="doctor_exp_yrs"
            label="Yrs of experience"
            size="small"
            value={doctor_exp_yrs || ""}
            onChange={(e) =>
              setFieldValue(
                "doctor_exp_yrs",
                validateIntNum(e.target.value, valueLimits.AGE)
              )
            }
            onBlur={handleBlur}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            helperText={
              errors.doctor_exp_yrs && touched.doctor_exp_yrs
                ? errors.doctor_exp_yrs
                : ""
            }
            error={
              errors.doctor_exp_yrs && touched.doctor_exp_yrs ? true : false
            }
          />
          <TextField
            fullWidth
            id="doctor_mobile"
            label="Whatsapp No."
            size="small"
            value={doctor_mobile || ""}
            onChange={(e) =>
              setFieldValue(
                "doctor_mobile",
                validateIntNum(e.target.value, valueLimits.MOBILE)
              )
            }
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            onBlur={handleBlur}
            helperText={
              errors.doctor_mobile && touched.doctor_mobile
                ? errors.doctor_mobile
                : ""
            }
            error={errors.doctor_mobile && touched.doctor_mobile ? true : false}
            inputProps={{
              maxLength: 10,
            }}
            InputLabelProps={{
              required: true,
            }}
          />
          <TextField
            fullWidth
            id="doctor_fee"
            label="Doctor Charges"
            size="small"
            value={doctor_fee || ""}
            onChange={(e) =>
              setFieldValue(
                "doctor_fee",
                validateFloatNum(e.target.value, valueLimits.MRP)
              )
            }
            onBlur={handleBlur}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            helperText={
              errors.doctor_fee && touched.doctor_fee ? errors.doctor_fee : ""
            }
            error={errors.doctor_fee && touched.doctor_fee ? true : false}
            InputLabelProps={{
              required: true,
            }}
          />
          <TextField
            fullWidth
            id="partner_charges"
            label="Partner Charges"
            size="small"
            value={partner_charges || ""}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            disabled
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1.5} mt={1.5} mb={-3}>
          <Typography variant="h6" color="primary">
            Update FEE for next appointment:<sup>*</sup>{" "}
          </Typography>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="update-label"
              name="update-group"
              value={allow_profile_update}
              onChange={(e) =>
                setFieldValue("allow_profile_update", e.target.value)
              }
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Box>
      <Box sx={{ mt: 3 }}>
        <DiscountPercent
          discount={discount}
          final_payable={final_payable}
          total={doctor_fee}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        />
      </Box>
    </>
  );
};

export default EditConsultationOrder;
