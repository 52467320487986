import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import EditBankDetailsModal from "../../../Dunzo/Providers/editBankDetailsModal";
import EditAddressModal from "../../../Providers/editAddressModal";
import EditGstModal from "../../../Providers/editGstModal";
import {
  getDaysAgo,
  infoToast,
  parseAndFormateDate,
} from "../../../../utils/common";
import useUserPermissions from "../../../../hooks/useUserPermissions";
import {
  AccessTime,
  AccountBalanceWallet,
  CheckCircle,
  ContentCopy,
  Edit,
  Error,
  LocationOnOutlined,
  PhoneAndroid,
} from "@mui/icons-material";
import BankLogo from "./bankLogo";
import CopyToClipboard from "react-copy-to-clipboard";
import KnowlarityCall from "../../../../components/Common/knowlarityCall";
import KycDetailsView from "./kycDetailsView";

const useStyles = makeStyles({
  accordion_collapsed: {
    borderRadius: "4px",
    "&::before": {
      backgroundColor: "transparent",
    },
  },
});

const Summary = ({
  providerInfo,
  setProviderInfo,
  kycData,
  setKycData,
  visitedTabs,
  updateVisitedTabs,
}: any) => {
  const [showAltBankDetail, setShowAltBankDetail] = useState(false);
  const [showEditBankDetailsModal, setShowEditBankDetailsModal] =
    useState<boolean>(false);
  const [showEditAddressModal, setShowEditAddressModal] =
    useState<boolean>(false);
  const [showEditGstModal, setShowEditGstModal] = useState<boolean>(false);
  const classes = useStyles();
  const userPermissions = useUserPermissions();
  const moduleScope = useMemo(
    () =>
      userPermissions?.find((item: any) => item.permission_id === "merchants")
        ?.scope,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const primaryBank = providerInfo?.bank_accounts?.find(
    (item: any) => item.primary === "y"
  );
  const altBank = providerInfo?.bank_accounts?.find(
    (item: any) => item.primary === "n" || !item.hasOwnProperty("primary")
  );

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{ backgroundColor: "#FFF6E7", borderRadius: 2 }}
      >
        <Box display="flex" gap={0.75} alignItems="center">
          {providerInfo?.app_version ? (
            <Box display="flex" gap={0.75} alignItems="center" mr={3}>
              <Tooltip title="App Version">
                <PhoneAndroid fontSize="small" />
              </Tooltip>
              <Typography variant="body2">
                {providerInfo?.app_version}
              </Typography>
            </Box>
          ) : null}
          {providerInfo?.last_app_active_at ? (
            <>
              <AccessTime fontSize="small" />
              <Typography variant="body2">
                Last App Active: {getDaysAgo(providerInfo?.last_app_active_at)}
              </Typography>
            </>
          ) : null}
        </Box>
        {/* <Button
          size="small"
          variant="outlined"
          sx={{ textTransform: "capitalize" }}
          startIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" rx="6.4" fill="#FFF6E7" />
              <path
                d="M23.168 13.1328H26.0352V26.7559H23.168V13.1328Z"
                fill="#FFA411"
              />
              <path
                opacity="0.2"
                d="M11.6992 20.3047H14.5665V26.756H11.6992V20.3047Z"
                fill="white"
              />
              <path
                d="M5.96484 23.8828H8.8321V26.7501H5.96484V23.8828Z"
                fill="#FFA411"
              />
              <path
                d="M5.96484 23.8828H8.8321V26.7501H5.96484V23.8828Z"
                fill="white"
              />
              <path
                d="M21.016 5.25L24.6001 5.96681M24.6001 5.96681L24.2775 9.55088M24.6001 5.96681L7.39654 19.5863M23.1664 26.7544V14.5686C23.1664 13.7768 23.8083 13.135 24.6001 13.135V13.135C25.3918 13.135 26.0337 13.7768 26.0337 14.5686V26.7544M17.4319 26.7544V18.1526C17.4319 17.3609 18.0738 16.719 18.8656 16.719V16.719C19.6573 16.719 20.2992 17.3609 20.2992 18.1526V26.7544M11.6974 26.7544V21.7367C11.6974 20.9449 12.3393 20.3031 13.131 20.3031V20.3031C13.9228 20.3031 14.5647 20.9449 14.5647 21.7367V26.7544M5.96291 26.7544V25.3208C5.96291 24.529 6.60476 23.8872 7.39654 23.8872V23.8872C8.18831 23.8872 8.83016 24.529 8.83016 25.3208V26.7544M26.7505 26.7544H5.24609"
                stroke="#001233"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
        >
          View Analytics
        </Button> */}
      </Box>
      <Grid container spacing={2} mt={0}>
        <Grid item xs={12} md={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ border: "1px solid #D6D9DE", borderRadius: 1.5, p: 1.5 }}
          >
            <Typography color="secondary" variant="body2">
              Phone No.
            </Typography>
            <Box display="flex" alignItems="center" gap={0.75}>
              <Typography variant="body2">
                +91 {providerInfo?.whatsapp_number || "--"}
              </Typography>
              <KnowlarityCall
                order={{
                  order_id: providerInfo?.location_id as string,
                  mobile: providerInfo?.whatsapp_number,
                }}
                showNumber={false}
                renderSmallIcon={true}
                service="merchant"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ border: "1px solid #D6D9DE", borderRadius: 1.5, p: 1.5 }}
          >
            <Typography color="secondary" variant="body2">
              GST Name / No.
            </Typography>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2">
                {providerInfo?.gst_name || "--"} /{" "}
                {providerInfo?.gst_no || "--"}
              </Typography>
              {moduleScope?.includes("edit") ? (
                <Edit
                  onClick={() => setShowEditGstModal(true)}
                  color="primary"
                  sx={{ cursor: "pointer" }}
                  fontSize="small"
                />
              ) : null}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ border: "1px solid #D6D9DE", borderRadius: 1.5, p: 1.5 }}
          >
            <Typography color="secondary" variant="body2">
              PAN
            </Typography>
            <Typography variant="body2">
              {providerInfo?.pan_no || "--"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={0}>
        <Grid item xs={12} md={6}>
          <Stack gap={2}>
            <Box sx={{ border: "1px solid #d6d9de", borderRadius: 1.5 }}>
              <Box
                sx={{
                  backgroundColor: "#F0F1F5",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                  p: 1.5,
                }}
              >
                <Typography variant="body2">
                  <strong>Settlement Mode</strong>
                </Typography>
              </Box>
              <Stack gap={1} p={1.5}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography color="secondary" variant="body2">
                    Default
                  </Typography>
                  <Typography variant="body2">
                    {providerInfo?.settlement_mode}
                  </Typography>
                </Box>
                {providerInfo?.static_qr_settlement_mode ? (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color="secondary" variant="body2">
                      Static QR
                    </Typography>
                    <Typography variant="body2">
                      {providerInfo?.static_qr_settlement_mode}
                    </Typography>
                  </Box>
                ) : null}
              </Stack>
            </Box>
            <Box sx={{ border: "1px solid #d6d9de", borderRadius: 1.5 }}>
              <Box
                sx={{
                  backgroundColor: "#F0F1F5",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                  p: 1.5,
                }}
              >
                <Typography variant="body2">
                  <strong>Bank Details</strong>
                </Typography>
              </Box>
              <Stack gap={1} p={1.5}>
                {primaryBank || altBank ? (
                  <>
                    {primaryBank ? (
                      <>
                        <Box display="flex" alignItems="center" gap={1}>
                          {primaryBank?.bank_icon ? (
                            <img
                              src={primaryBank?.bank_icon}
                              alt={primaryBank.bank_name}
                              height={20}
                              width={20}
                            />
                          ) : (
                            <BankLogo name={String(primaryBank?.bank_name)} />
                          )}
                          <Typography variant="body2">
                            <strong>{providerInfo?.bank_name}</strong>
                            <span style={{ color: "#667185" }}>
                              {" "}
                              &nbsp;|&nbsp; Primary Account
                            </span>
                          </Typography>
                          {primaryBank.account_verification === "verified" ? (
                            <CheckCircle
                              fontSize="small"
                              sx={{ color: "#4CAF50" }}
                            />
                          ) : (
                            <Tooltip title={primaryBank?.account_verification}>
                              <Error fontSize="small" color="warning" />
                            </Tooltip>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography color="secondary" variant="body2">
                            Account Holder Name
                          </Typography>
                          <Typography variant="body2">
                            {primaryBank?.account_name}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography color="secondary" variant="body2">
                            Account Number
                          </Typography>
                          <Typography variant="body2">
                            {primaryBank?.account_no}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography color="secondary" variant="body2">
                            IFSC Code
                          </Typography>
                          <Typography variant="body2">
                            {primaryBank?.ifsc_code}
                          </Typography>
                        </Box>
                      </>
                    ) : null}

                    {altBank ? (
                      <>
                        <Typography mt={1.5} variant="h6">
                          Alternate Bank Account
                        </Typography>
                        <Accordion
                          expanded={showAltBankDetail}
                          onChange={() => setShowAltBankDetail((prev) => !prev)}
                          sx={{
                            boxShadow: "0 0 .25rem rgba(0,0,0,.075)!important",
                          }}
                          className={classes.accordion_collapsed}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                          >
                            <Box display="flex" alignItems="center" gap={1.5}>
                              {altBank?.bank_icon ? (
                                <img
                                  src={altBank?.bank_icon}
                                  alt={altBank.bank_name}
                                  height={20}
                                  width={20}
                                />
                              ) : (
                                <BankLogo name={String(altBank?.bank_name)} />
                              )}
                              <Typography variant="body2" fontWeight="bold">
                                {altBank?.bank_name}
                              </Typography>
                              {altBank?.account_verification === "verified" ? (
                                <CheckCircle
                                  fontSize="small"
                                  sx={{ color: "#4CAF50" }}
                                />
                              ) : (
                                <Tooltip title={altBank?.account_verification}>
                                  <Error fontSize="small" color="warning" />
                                </Tooltip>
                              )}
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack gap={1}>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography color="secondary" variant="body2">
                                  Account Holder Name
                                </Typography>
                                <Typography variant="body2">
                                  {altBank?.account_name}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography color="secondary" variant="body2">
                                  Account Number
                                </Typography>
                                <Typography variant="body2">
                                  {altBank?.account_no}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography color="secondary" variant="body2">
                                  IFSC Code
                                </Typography>
                                <Typography variant="body2">
                                  {altBank?.ifsc_code}
                                </Typography>
                              </Box>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    ) : null}
                  </>
                ) : (
                  <Typography variant="body2" color="secondary">
                    No Data Found.
                  </Typography>
                )}

                {providerInfo?.upi_details?.length ? (
                  <>
                    <Typography mt={1.5} variant="h6">
                      UPI IDs
                    </Typography>
                    <Stack>
                      {providerInfo?.upi_details?.map((x: any, i: number) => {
                        return (
                          <Box key={i}>
                            <Box
                              p={1}
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Typography variant="body2">
                                {x.upi_name}{" "}
                                {x.primary === "y" ? (
                                  <span style={{ color: "#667185" }}>
                                    {" "}
                                    | Primary Account
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Typography>
                              <Box
                                display="flex"
                                alignItems="center"
                                gap={0.75}
                              >
                                <Typography variant="body2">
                                  {x.upi_id}
                                </Typography>
                                <CopyToClipboard
                                  text={x.upi_id}
                                  onCopy={() =>
                                    infoToast("Copied to clipboard")
                                  }
                                >
                                  <IconButton color="primary" size="small">
                                    <ContentCopy fontSize="small" />
                                  </IconButton>
                                </CopyToClipboard>
                              </Box>
                            </Box>
                            <Divider />
                          </Box>
                        );
                      })}
                    </Stack>
                  </>
                ) : null}

                {providerInfo?.wallet_balance ? (
                  <Box
                    sx={{
                      borderRadius: 1.5,
                      border: "1px solid #9EE3E1",
                    }}
                    p={1.5}
                    mt={1.5}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <AccountBalanceWallet />
                      <Typography variant="body2">
                        Wallet Balance: &#8377; {providerInfo?.wallet_balance}
                      </Typography>
                    </Stack>
                    <Typography color="secondary" variant="body2">
                      Updated At:{" "}
                      {parseAndFormateDate(providerInfo?.wallet_updated_at)}
                    </Typography>
                  </Box>
                ) : null}

                <Box
                  sx={{
                    backgroundColor: "#EAF9F8",
                    borderRadius: 1.5,
                    border: "1px solid #9EE3E1",
                  }}
                  p={1.5}
                  mt={1.5}
                >
                  <Typography variant="body2">
                    <span style={{ color: "#667185" }}>
                      Tax Deducated at Source(TDS):
                    </span>{" "}
                    {providerInfo?.tds_tcs_details?.tds_percentage}%{" "}
                    <span style={{ color: "#667185" }}>
                      &emsp;|&emsp; Tax Collected at Source(TCS):
                    </span>{" "}
                    {providerInfo?.tds_tcs_details?.tcs_percentage}%
                  </Typography>
                </Box>
              </Stack>
            </Box>
            {providerInfo?.target_discount_pct ? (
              <Box sx={{ border: "1px solid #d6d9de", borderRadius: 1.5 }}>
                <Box
                  sx={{
                    backgroundColor: "#F0F1F5",
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    p: 1.5,
                  }}
                >
                  <Typography variant="body2">
                    <strong>Offers</strong>
                  </Typography>
                </Box>
                <Stack gap={1} p={1.5}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color="secondary" variant="body2">
                      Target Discount Percentage
                    </Typography>
                    <Typography variant="body2">
                      {providerInfo?.target_discount_pct} %
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            ) : null}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack gap={2}>
            <KycDetailsView
              activeLocationId={providerInfo?.location_id}
              kycData={kycData}
              setKycData={setKycData}
              visitedTabs={visitedTabs}
              updateVisitedTabs={updateVisitedTabs}
            />

            <Box sx={{ border: "1px solid #d6d9de", borderRadius: 1.5 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  backgroundColor: "#F0F1F5",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                  p: 1.5,
                  z: 1,
                  position: "relative",
                }}
              >
                <Typography fontWeight="bold" variant="body2">
                  Contact Details
                </Typography>
                {moduleScope?.includes("edit") ? (
                  <Edit
                    onClick={() => setShowEditAddressModal(true)}
                    color="primary"
                    sx={{ cursor: "pointer" }}
                    fontSize="small"
                  />
                ) : null}
              </Box>
              <Box p={1.5}>
                <Box display="flex" alignItems="flex-start" gap={0.5}>
                  <LocationOnOutlined color="primary" fontSize="small" />
                  <Stack>
                    <Typography color="secondary">Address:</Typography>

                    <Typography variant="body2">
                      {providerInfo?.formatted_address}
                    </Typography>
                  </Stack>
                </Box>
                <Grid mt={0} container spacing={2}>
                  <Grid item xs={6} md={3}>
                    <Typography color="secondary">Area:</Typography>
                    <Typography variant="body2">
                      {providerInfo?.area || "--"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography color="secondary">City:</Typography>
                    <Typography variant="body2">
                      {providerInfo?.city || "--"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography color="secondary">State:</Typography>
                    <Typography variant="body2">
                      {providerInfo?.state || "--"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography color="secondary">Pincode:</Typography>
                    <Typography variant="body2">
                      {providerInfo?.pin_code || "--"}
                    </Typography>
                  </Grid>
                </Grid>
                <Box py={2}>
                  <Divider />
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Stack>
                    <Typography color="secondary">Location ID</Typography>
                    <Typography variant="body2">
                      {providerInfo?.location_id}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography color="secondary">Provider ID</Typography>
                    <Typography variant="body2">
                      {providerInfo?.provider_id}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Box>

            <Box sx={{ border: "1px solid #d6d9de", borderRadius: 1.5 }}>
              <Box
                sx={{
                  backgroundColor: "#F0F1F5",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                  p: 1.5,
                  z: 1,
                  position: "relative",
                }}
              >
                <Typography fontWeight="bold" variant="body2">
                  Owner Details
                </Typography>
              </Box>
              <Grid container>
                <Grid item xs={6} md={5}>
                  <Box p={1.5}>
                    <Typography color="secondary">Name:</Typography>
                    <Typography
                      mt={1}
                      variant="body2"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {providerInfo?.owner_name || "--"}
                    </Typography>

                    <Typography mt={3} color="secondary">
                      Email
                    </Typography>
                    <Typography variant="body2">
                      {providerInfo?.email_id || "--"}
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid
                  sx={{ borderLeft: "1px solid #D6D9DE" }}
                  item
                  xs={6}
                  md={7}
                >
                  <Stack gap={1} p={1.5}>
                    <Typography color="secondary">
                      Documents Submitted(3)
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={1.5}
                      sx={{ backgroundColor: "#F0F1F5", borderRadius: 1.5 }}
                    >
                      <Stack direction="row" alignItems="center" gap={1.5}>
                        <Box
                          style={{
                            backgroundColor: "#d6d9de",
                            height: "24px",
                            width: "24px",
                          }}
                        ></Box>
                        <Typography variant="body2">
                          Storefront Image
                        </Typography>
                      </Stack>
                      <Visibility
                        fontSize="small"
                        sx={{ color: "#1c1b1f", cursor: "pointer" }}
                      />
                    </Box>
                    <Button
                      sx={{ textTransform: "capitalize" }}
                      variant="outlined"
                    >
                      View All
                    </Button>
                  </Stack>
                </Grid> */}
              </Grid>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <>
        {showEditBankDetailsModal && (
          <EditBankDetailsModal
            showEditBankDetailsModal={showEditBankDetailsModal}
            setShowEditBankDetailsModal={setShowEditBankDetailsModal}
            providerInfo={providerInfo}
            setProviderInfo={setProviderInfo}
            providersData={null}
            setProvidersData={null}
            providerType="non_dunzo"
          />
        )}
        {showEditAddressModal && (
          <EditAddressModal
            showEditAddressModal={showEditAddressModal}
            setShowEditAddressModal={setShowEditAddressModal}
            providerInfo={providerInfo}
            setProviderInfo={setProviderInfo}
            providersData={null}
            setProvidersData={null}
          />
        )}
        {showEditGstModal && (
          <EditGstModal
            showEditGstModal={showEditGstModal}
            setShowEditGstModal={setShowEditGstModal}
            providerInfo={providerInfo}
            setProviderInfo={setProviderInfo}
          />
        )}
      </>
    </div>
  );
};

export default Summary;
