import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { commonTableOptions } from "../../constants";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import {
  customReplaceAll,
  handleError,
  successToast,
} from "../../utils/common";
import IssueDetailModal2 from "./issueDetailModal2";
import EmailReportModalAppIssues from "./emailReportModalAppIssues";
import AddIcon from "@mui/icons-material/Add";
import CreateTicketModal from "./createTicketModal";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SearchIcon from "@mui/icons-material/Search";
import IssueRowView from "./issueRowView";
import { IssueHistoryModal } from "./issueHistoryModal";
import { format, parse } from "date-fns";
import ProviderSettingsInfoModal from "../Providers/providerSettingsInfoModal";

const AppIssues = () => {
  const [loading, setLoading] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [issuesData, setIssuesData] = useState<any>([]);
  const [filteredIssuesData, setFilteredIssuesData] = useState<any>([]);
  const privateInstance = useAxiosInstance();
  const [activeIssueId, setActiveIssueId] = useState<any>(null);
  const [showIssueDetailModal, setShowIssueDetailModal] = useState(false);
  const [showIssueHistoryModal, setShowIssueHistoryModal] = useState(false);
  const [showProviderSettingsInfoModal, setShowProviderSettingsInfoModal] =
    useState(false);
  const [activeLocationId, setActiveLocationId] = useState<string>("");
  const [showCreateTicket, setShowCreateTicket] = useState(false);
  const user: AuthenticatedUser = useUserInfo();
  const [issueStage, setIssueStage] = useState("ongoing");
  const [filters, setFilters] = useState<any>({
    issue_type: "All",
    follow_up_date: "All",
    assigned_team: "All",
    issue_status: "All",
  });
  const [searchInput, setSearchInput] = useState("");

  const getIssuesList = async () => {
    //reset local state on api call
    setIssuesData([]);

    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/issues?type=${issueStage}`
      );
      if (result.data?.status === API_RESULT.SUCCESS) {
        setIssuesData(result.data.response);
        renderUpdatedTable(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const renderUpdatedTable = (dataFromParam: any) => {
    //start: prevent render if data is empty []
    if (dataFromParam?.length === 0) {
      return false;
    }
    //end
    let newData = dataFromParam;
    for (const item in filters) {
      newData =
        filters[item] === "All"
          ? newData
          : filters[item] === "no_follow_up"
          ? newData.filter((x: any) => !x[item])
          : newData.filter((x: any) => x[item] === filters[item]);
    }
    const currentTableData = newData;
    if (searchInput) {
      const formattedInput = searchInput?.trim()?.toLowerCase();
      setFilteredIssuesData(
        currentTableData.filter(
          (x: any) =>
            x.issue_id?.includes(formattedInput) ||
            x.location_id?.includes(formattedInput) ||
            x.provider_name?.toLowerCase()?.includes(formattedInput)
        )
      );
    } else {
      setFilteredIssuesData(currentTableData);
    }
  };

  useEffect(() => {
    renderUpdatedTable([...issuesData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, searchInput]);

  useEffect(() => {
    getIssuesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueStage]);

  const columns = [
    {
      name: "issue_id",
      label: "ISSUE DETAILS",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "location_id",
      label: "LOCATION ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "issue_type",
      label: "ISSUE TYPE",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "issue_sub_type",
      label: "CATEGORY",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "follow_up_date",
      label: "FOLLOW UP",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "assigned_team",
      label: "ASSIGNED TEAM",
      options: {
        filter: true,
        sort: false,
        display: false,
        filterOptions: {
          renderValue: (val: string) => {
            return val?.split("_")?.join(" ");
          },
        },
      },
    },
    {
      name: "provider_name",
      label: "PROVIDER NAME",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "description.text",
      label: "DESCRIPTION",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "issue_status",
      label: "STATUS",
      options: {
        filter: true,
        filterOptions: {
          renderValue: (val: string) => {
            return val?.split("_")?.join(" ");
          },
        },
        sort: false,
        searchable: false,
        // display: value === 0 ? true : false,
      },
    },
    {
      name: "created_at",
      label: "CREATED AT",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        filter: false,
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...(commonTableOptions as any),
    download: false,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    search: false,
    filter: false,
    responsive: "vertical",
    customRowRender: (data: any, dataIndex: number) => {
      return (
        <IssueRowView
          key={data[0]}
          tableRowData={data}
          tableRowIndex={dataIndex}
          updateIssuesData={updateIssuesData}
          setShowIssueDetailModal={setShowIssueDetailModal}
          setShowIssueHistoryModal={setShowIssueHistoryModal}
          setActiveIssueId={setActiveIssueId}
          setShowProviderSettingsInfoModal={setShowProviderSettingsInfoModal}
          setActiveLocationId={setActiveLocationId}
          isActionDisabled={data[8] === "resolved"}
        />
      );
    },
  };

  const updateIssuesData = async (issueId: string, payload: any) => {
    const res = await privateInstance.patch(
      `${API_VERSION.V1}/issues/${issueId}`,
      payload
    );

    if (res?.data?.status === API_RESULT.SUCCESS) {
      successToast("Updated Successfully");
      //update table UI Data;
      const itemIndex = issuesData.findIndex(
        (p: any) => p.issue_id === issueId
      );
      issuesData.splice(itemIndex, 1, {
        ...issuesData[itemIndex],
        ...payload,
      });
      setIssuesData([...issuesData]);
      renderUpdatedTable([...issuesData]);
      //end
    }
  };

  return (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        gap={1}
        justifyContent="end"
        alignItems="center"
      >
        <Box>
          <Button
            sx={{ minWidth: "fit-content", mr: 1.5 }}
            size="small"
            color="primary"
            variant="contained"
            onClick={() => setShowCreateTicket(true)}
            startIcon={<AddIcon fontSize="small" />}
          >
            Create
          </Button>
          {user?.role === USER_ROLE.VIEWER ? null : (
            <Button
              sx={{ minWidth: "fit-content" }}
              size="small"
              color="primary"
              variant="outlined"
              onClick={() => setShowReportModal(true)}
              startIcon={<ForwardToInboxIcon fontSize="small" />}
            >
              Report
            </Button>
          )}
        </Box>
      </Box>
      <Box mt={3}>
        <Grid container spacing={{ xs: 2, sm: 12 }} mb={1.5}>
          <Grid item xs={12} md={8}>
            <Stack direction="row" gap={1.5}>
              <FormControl
                size="small"
                fullWidth
                sx={{ textTransform: "capitalize" }}
              >
                <InputLabel id="label-stage">Stage</InputLabel>
                <Select
                  label="Stage"
                  labelId="label-stage"
                  id="stage"
                  value={issueStage}
                  onChange={(e) => {
                    setIssueStage(e.target.value);
                    //start: clear table data, reset filters, clear search input on change of this filter
                    setIssuesData([]);
                    setFilteredIssuesData([]);
                    setFilters({
                      issue_type: "All",
                      follow_up_date: "All",
                      assigned_team: "All",
                      issue_status: "All",
                    });
                    setSearchInput("");
                    //end
                  }}
                >
                  {["ongoing", "resolved"].map((x: string) => {
                    return (
                      <MenuItem
                        key={x}
                        value={x}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {x}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {Object.keys(filters).map((filterItem: string, index: number) => {
                return (
                  <FormControl
                    key={filterItem}
                    size="small"
                    fullWidth
                    sx={{ textTransform: "capitalize" }}
                  >
                    <InputLabel id={`label-${filterItem}`}>
                      {customReplaceAll(filterItem, "_", " ")}
                    </InputLabel>
                    <Select
                      labelId={`label-${filterItem}`}
                      id={filterItem}
                      label={customReplaceAll(filterItem, "_", " ")}
                      // displayEmpty
                      value={filters[filterItem]}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          [filterItem]: e.target.value,
                        });
                      }}
                    >
                      {/* add option "all", get unique records, filter redundant data and map to render options */}
                      {[
                        "All",
                        ...(new Set(
                          filterItem === "follow_up_date"
                            ? issuesData
                                ?.map((t: any) => t[filterItem])
                                ?.filter((u: any) => u !== null && u !== "")
                                ?.map((v: any) =>
                                  parse(v, "dd-MM-yyyy", new Date())
                                )
                                ?.sort((a: any, b: any) => a - b)
                                ?.map((w: any) => format(w, "dd-MM-yyyy"))
                            : issuesData?.map((t: any) => t[filterItem])
                        ) as any),
                        filterItem === "follow_up_date" ? "no_follow_up" : "",
                      ]
                        ?.filter(
                          (o) => o !== null && o !== "" && o !== undefined
                        )
                        ?.map((x: string) => {
                          return (
                            <MenuItem
                              key={x}
                              value={x}
                              sx={{ textTransform: "capitalize" }}
                            >
                              {customReplaceAll(String(x), "_", " ")}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                );
              })}
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              type="search"
              fullWidth
              size="small"
              id="input-with-icon-textfield"
              label="Search"
              placeholder="Issue ID / Location ID / Name"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </Grid>
        </Grid>

        <MUIDataTable
          title=""
          data={filteredIssuesData}
          columns={columns}
          options={options}
        />
      </Box>
      {showIssueDetailModal && (
        <IssueDetailModal2
          open={showIssueDetailModal}
          onClose={() => setShowIssueDetailModal(false)}
          data={issuesData?.find((x: any) => x.issue_id === activeIssueId)}
          activeIssueId={activeIssueId}
          getIssuesList={getIssuesList}
          updateIssuesData={updateIssuesData}
          setShowIssueHistoryModal={setShowIssueHistoryModal} //to close parent modal
        />
      )}
      {showIssueHistoryModal && (
        <IssueHistoryModal
          open={showIssueHistoryModal}
          onClose={() => setShowIssueHistoryModal(false)}
          data={issuesData?.find((x: any) => x.issue_id === activeIssueId)}
          updateIssuesData={updateIssuesData}
          setShowIssueDetailModal={setShowIssueDetailModal}
          setActiveIssueId={setActiveIssueId}
        />
      )}
      {showProviderSettingsInfoModal && (
        <ProviderSettingsInfoModal
          showProviderSettingsInfoModal={showProviderSettingsInfoModal}
          setShowProviderSettingsInfoModal={setShowProviderSettingsInfoModal}
          activeLocationId={activeLocationId}
          providersData={null}
          setProvidersData={null}
          path="app-issues"
          targetId={
            issuesData?.find((x: any) => x.location_id === activeLocationId)
              ?.issue_id
          }
        />
      )}
      {showReportModal ? (
        <EmailReportModalAppIssues
          show={showReportModal}
          setShow={setShowReportModal}
        />
      ) : null}
      {showCreateTicket ? (
        <CreateTicketModal
          open={showCreateTicket}
          onClose={() => setShowCreateTicket(false)}
          getIssuesList={getIssuesList}
        />
      ) : null}
    </div>
  );
};

export default AppIssues;
