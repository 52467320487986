import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { handleError } from "../../../../utils/common";
import { Visibility } from "@mui/icons-material";

const KycDetailsView = ({
  activeLocationId,
  kycData,
  setKycData,
  visitedTabs,
  updateVisitedTabs,
}: any) => {
  const [loadingKyc, setLoadingKyc] = useState(false);
  const privateInstance = useAxiosInstance();
  const [viewAllKyc, setViewAllKyc] = useState(false);

  useEffect(() => {
    const getKycData = async () => {
      setLoadingKyc(true);
      try {
        const result = await privateInstance.get(
          `${API_VERSION.V1}/orders/provider/kyc?location_id=${activeLocationId}`
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          updateVisitedTabs(0, "add");
          setKycData(result.data?.response);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoadingKyc(false);
      }
    };
    if (!visitedTabs.includes(0)) {
      getKycData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ border: "1px solid #d6d9de", borderRadius: 1.5 }}>
      <Box
        sx={{
          backgroundColor: "#F0F1F5",
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          p: 1.5,
          z: 1,
          position: "relative",
        }}
      >
        <Typography fontWeight="bold" variant="body2">
          KYC Details
        </Typography>
      </Box>
      {loadingKyc ? (
        <CircularProgress size={20} />
      ) : (
        <>
          {kycData ? (
            <Grid container>
              <Grid item xs={6} md={5}>
                <Box p={1.5}>
                  <Typography color="secondary">KYC Status</Typography>
                  {kycData?.status ? (
                    <>
                      {kycData.status === "pending" ? (
                        <Chip
                          size="small"
                          label="PENDING"
                          sx={{ backgroundColor: "#FBD38D" }}
                        />
                      ) : kycData.status === "completed" ? (
                        <Chip
                          size="small"
                          label="COMPLETED"
                          sx={{ backgroundColor: "#C6F6D5" }}
                        />
                      ) : (
                        <Chip
                          size="small"
                          label="REJECTED"
                          sx={{ backgroundColor: "#C53030", color: "#FFF" }}
                        />
                      )}
                    </>
                  ) : null}

                  <Typography mt={3} color="secondary">
                    Date of Submission
                  </Typography>
                  <Typography variant="body2">
                    {kycData?.created_at?.split(" ")?.join(", ")}
                  </Typography>
                </Box>
              </Grid>
              <Grid sx={{ borderLeft: "1px solid #D6D9DE" }} item xs={6} md={7}>
                <Stack gap={1} p={1.5}>
                  <Typography color="secondary">
                    KYC Verification Documents(
                    {kycData?.store_front_images.length +
                      (kycData?.gst_certificate ? 1 : 0) +
                      (kycData?.license_certificate ? 1 : 0)}
                    )
                  </Typography>
                  {kycData?.store_front_images?.length ? (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={1.5}
                      sx={{ backgroundColor: "#F0F1F5", borderRadius: 1.5 }}
                    >
                      <Stack direction="row" alignItems="center" gap={1.5}>
                        {kycData?.store_front_images?.map((x: string) => {
                          return (
                            <img
                              onError={(e: any) => {
                                e.target.src =
                                  "https://placehold.jp/100x100.png";
                              }}
                              onClick={() => window.open(x, "_blank")}
                              src={x}
                              alt="img"
                              height={36}
                              width={36}
                              style={{ cursor: "pointer" }}
                            />
                          );
                        })}
                        <Typography variant="body2">
                          Storefront Image
                        </Typography>
                      </Stack>
                      <Visibility
                        fontSize="small"
                        sx={{ color: "#1c1b1f", cursor: "pointer" }}
                        onClick={() =>
                          window.open(kycData?.store_front_images[0], "_blank")
                        }
                      />
                    </Box>
                  ) : null}
                  {viewAllKyc ? (
                    <>
                      {kycData?.gst_certificate ? (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          p={1.5}
                          sx={{
                            backgroundColor: "#F0F1F5",
                            borderRadius: 1.5,
                          }}
                        >
                          <Stack direction="row" alignItems="center" gap={1.5}>
                            <img
                              onError={(e: any) => {
                                e.target.src =
                                  "https://cdn2.iconfinder.com/data/icons/file-8/128/file-expand_Pdf-512.png";
                              }}
                              onClick={() =>
                                window.open(kycData?.gst_certificate, "_blank")
                              }
                              src={kycData?.gst_certificate}
                              alt="img"
                              height={36}
                              width={36}
                              style={{ cursor: "pointer" }}
                            />
                            <Typography variant="body2">
                              GST Certificate
                            </Typography>
                          </Stack>
                          <Visibility
                            fontSize="small"
                            sx={{ color: "#1c1b1f", cursor: "pointer" }}
                            onClick={() =>
                              window.open(kycData?.gst_certificate, "_blank")
                            }
                          />
                        </Box>
                      ) : null}
                      {kycData?.license_certificate ? (
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          p={1.5}
                          sx={{
                            backgroundColor: "#F0F1F5",
                            borderRadius: 1.5,
                          }}
                        >
                          <Stack direction="row" alignItems="center" gap={1.5}>
                            <img
                              onError={(e: any) => {
                                e.target.src =
                                  "https://cdn2.iconfinder.com/data/icons/file-8/128/file-expand_Pdf-512.png";
                              }}
                              onClick={() =>
                                window.open(
                                  kycData?.license_certificate,
                                  "_blank"
                                )
                              }
                              src={kycData?.license_certificate}
                              alt="img"
                              height={36}
                              width={36}
                              style={{ cursor: "pointer" }}
                            />
                            <Typography variant="body2">
                              License Certificate
                            </Typography>
                          </Stack>
                          <Visibility
                            fontSize="small"
                            sx={{ color: "#1c1b1f", cursor: "pointer" }}
                            onClick={() =>
                              window.open(
                                kycData?.license_certificate,
                                "_blank"
                              )
                            }
                          />
                        </Box>
                      ) : null}
                    </>
                  ) : null}
                  <Button
                    sx={{ textTransform: "capitalize" }}
                    variant="outlined"
                    onClick={() => setViewAllKyc((prev) => !prev)}
                  >
                    {viewAllKyc ? "View Less" : "View More"}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="body2" color="secondary" p={1.5}>
              No Data Found.
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default KycDetailsView;
