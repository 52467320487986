import React from "react";
import Icici from "../../../../assets/icons/icici1.png";
import Hdfc from "../../../../assets/icons/hdfc.png";
import Axis from "../../../../assets/icons/axis.png";
import Sbi from "../../../../assets/icons/sbi.png";
import Kotak from "../../../../assets/icons/kotak1.png";
import Canara from "../../../../assets/icons/canara.png";
import Idfc from "../../../../assets/icons/idfc.png";
import Default from "../../../../assets/icons/default.svg";

const BankLogo = ({ name }: { name: string }) => {
  name = name?.toLowerCase()?.trim()?.split(" ")?.[0];

  const getLogo = () => {
    let res;
    switch (name) {
      case "hdfc":
        res = Hdfc;
        break;
      case "icici":
        res = Icici;
        break;
      case "axis":
        res = Axis;
        break;
      case "sbi":
        res = Sbi;
        break;
      case "kotak":
        res = Kotak;
        break;
      case "canara":
        res = Canara;
        break;
      case "idfc":
        res = Idfc;
        break;
      default:
        res = Default;
        break;
    }
    return res;
  };

  return <img src={getLogo()} alt={name} height={20} width={20} />;
};

export default BankLogo;
