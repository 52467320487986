import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable, { FilterType } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { commonTableOptions } from "../../../constants";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { handleError, parseAndFormateDate } from "../../../utils/common";
import { Add, Info } from "@mui/icons-material";
import AddDeviceModal from "./addDeviceModal";
import DeviceAuditModal from "./deviceAuditModal";

const Devices = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [showAddDevice, setShowAddDevice] = useState(false);
  const [showAudit, setShowAudit] = useState(false);
  const [activeDeviceId, setActiveDeviceId] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V2}/analytics/digital-payments/providers?filter=devices`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setData(
          result?.data?.response?.devices?.map((d: any) => {
            return {
              ...d,
              terminal_id: d.terminal_id ?? "NA",
              active: d.active ? "yes" : "no",
            };
          })
        );
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cols = [
    {
      name: "device_id",
      label: "Device ID",
      options: {
        filterType: "textField" as FilterType,
        customBodyRender: (value: any) => {
          return (
            <Stack gap={0.5} alignItems="center" direction="row">
              <Typography variant="body2">{value}</Typography>
              <IconButton
                onClick={() => {
                  setShowAudit(true);
                  setActiveDeviceId(value);
                }}
                size="small"
              >
                <Tooltip title="Audit">
                  <Info color="info" />
                </Tooltip>
              </IconButton>
            </Stack>
          );
        },
      },
    },
    {
      name: "partner",
      label: "Partner",
    },
    {
      name: "terminal_id",
      label: "Terminal ID",
      options: {
        filterType: "textField" as FilterType,
        customBodyRender: (value: any) => {
          return (
            <>
              {value === "NA" ? (
                <Typography variant="body2">{value}</Typography>
              ) : (
                <Typography
                  onClick={() => {
                    window.open(
                      `/digital-payment/qr-terminals?terminal_id=${value}`
                    );
                  }}
                  variant="body2"
                  color="primary"
                  sx={{ cursor: "pointer" }}
                >
                  {value}
                </Typography>
              )}
            </>
          );
        },
      },
    },
    {
      name: "active",
      label: "Device in Use",
      options: {
        customBodyRender: (value: any) => {
          return (
            <Typography
              variant="body2"
              sx={{ textTransform: "capitalize" }}
              color={value === "yes" ? "green" : "error"}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "modified_at",
      label: "Modified At",
      options: {
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <Typography variant="body2">
              {parseAndFormateDate(value)}
            </Typography>
          );
        },
      },
    },
    {
      name: "modified_by",
      label: "Modified By",
      options: {
        filter: false,
      },
    },
  ];

  const CustomToolbar = () => {
    return (
      <Button
        startIcon={<Add />}
        variant="outlined"
        size="small"
        sx={{ ml: 1.5 }}
        onClick={() => setShowAddDevice(true)}
      >
        Add Device
      </Button>
    );
  };

  const options: any = {
    customToolbar: () => <CustomToolbar />,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    searchPlaceholder: "Device ID/Partner/Terminal ID",
  };

  return (
    <Box>
      <MUIDataTable title="" data={data} columns={cols} options={options} />
      {showAddDevice ? (
        <AddDeviceModal
          open={showAddDevice}
          onClose={() => setShowAddDevice(false)}
          getData={getData}
        />
      ) : null}
      {showAudit ? (
        <DeviceAuditModal
          open={showAudit}
          onClose={() => setShowAudit(false)}
          activeDeviceId={activeDeviceId}
        />
      ) : null}
    </Box>
  );
};

export default Devices;
