import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import IssueRowView from "./issueRowView";
import { useEffect, useState } from "react";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import { handleError } from "../../utils/common";

export const IssueHistoryModal = ({
  open,
  onClose,
  data,
  updateIssuesData,
  setShowIssueDetailModal,
  setActiveIssueId,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [pharmacyIssuesData, setPharmacyIssuesData] = useState([]);
  const privateInstance = useAxiosInstance();

  const getPharmacyIssuesData = async () => {
    let url = `${API_VERSION.V1}/issues?location_id=${data?.location_id}`;

    setLoading(true);
    try {
      const result = await privateInstance.get(url);
      if (result.data?.status === API_RESULT.SUCCESS) {
        setPharmacyIssuesData(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPharmacyIssuesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
      >
        <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
          Issues History - {data?.provider_name}
        </CustomDialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {pharmacyIssuesData?.map((x: any, i: number) => {
                return (
                  <IssueRowView
                    key={i}
                    tableRowData={[
                      x.issue_id,
                      x.location_id,
                      x.issue_type,
                      x.issue_sub_type,
                      x.follow_up_date,
                      x.assigned_team,
                      x.provider_name,
                      x.description.text,
                      x.issue_status,
                      x.created_at,
                    ]}
                    tableRowIndex={i}
                    updateIssuesData={updateIssuesData}
                    setShowIssueDetailModal={setShowIssueDetailModal}
                    setShowIssueHistoryModal={null}
                    setActiveIssueId={setActiveIssueId}
                    setShowProviderSettingsInfoModal={null}
                    setActiveLocationId={null}
                    isActionDisabled={true}
                  />
                );
              })}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
