import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Input,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomDialogTitle from "../../../../components/Common/customDialogTitle";
import { Percent } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { errorToast, handleError } from "../../../../utils/common";

const ActivatePrimeModal = ({
  open,
  onClose,
  providerInfo,
  getProviderInfoFn,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(
    providerInfo?.prime_store_config?.coins || 1
  );
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value === "" ? 0 : Number(event.target.value));
  };
  const handleBlur = () => {
    if (value <= 0) {
      setValue(1);
    } else if (value > 20) {
      setValue(20);
    }
  };
  const privateInstance = useAxiosInstance();

  const handleSubmit = async () => {
    if (window.confirm("Are you sure?")) {
      let payload = {
        update_type: "activate_prime_store",
        coins: Number(value),
        slug_id: providerInfo?.store_link?.split("store/")?.[1],
        location_id: providerInfo?.location_id,
        provider_id: providerInfo?.provider_id,
      };

      setLoading(true);

      try {
        const result = await privateInstance.put(
          `${API_VERSION.V1}/orders/provider`,
          payload
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          onClose();
          getProviderInfoFn(); //call api to update UI
        } else {
          errorToast(result.data.message);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
          {providerInfo?.prime_store ? "Update" : "Activate"} Prime
        </CustomDialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            id="slug_id"
            label="Slug ID"
            size="small"
            value={providerInfo?.store_link?.split("store/")?.[1]}
            disabled
            sx={{ mt: 1.5 }}
          />
          <Box mt={3}>
            <Typography id="input-slider" gutterBottom>
              Prime Coins (%)
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  disabled={loading}
                  max={20}
                  value={typeof value === "number" ? value : 0}
                  onChange={handleSliderChange}
                  aria-labelledby="input-slider"
                />
              </Grid>
              <Grid item>
                <Input
                  disabled={loading}
                  sx={{ width: "75px", ml: 3 }}
                  value={value}
                  size="small"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 20,
                    type: "number",
                    "aria-labelledby": "input-slider",
                    style: {
                      textAlign: "center",
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Percent />
              </Grid>
            </Grid>
          </Box>
          <Box mt={5} textAlign="center">
            <LoadingButton
              size="medium"
              color="primary"
              loading={loading}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              variant="contained"
              type="button"
              onClick={handleSubmit}
            >
              Submit
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ActivatePrimeModal;
