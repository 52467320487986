import {
  Box,
  Chip,
  CircularProgress,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { a11yProps, handleError } from "../../../utils/common";
import TabPanel from "../../../components/UI/tabPanel";
import Summary from "./Summary";
import MerchantTransactions from "./Transactions";
import MerchantSettlements from "./Settlements";
import MerchantTickets from "./Tickets";
import MedpayQr from "./MedpayQr";
import { AccessTime, ChevronRight } from "@mui/icons-material";
import MedpayPrime from "./MedpayPrime";
import PrimeStoreIcon from "../../../assets/images/primestore.svg";

const initialDateRange = {
  label: "Today",
  startDate: new Date(),
  endDate: new Date(),
};

const SingleMerchant = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [txnData, setTxnData] = useState<any>([]);
  const [settlementData, setSettlementData] = useState<any>([]);
  const [medpayQrData, setMedpayQrData] = useState([]);
  const [issuesData, setIssuesData] = useState<any>([]);
  const privateInstance = useAxiosInstance();
  const params = useParams();
  const activeLocationId = params?.locationId;
  const location = useLocation();
  const activeTab: any = new URLSearchParams(location.search).get("tab") || 1;
  const [value, setValue] = useState(Number(activeTab - 1));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const [dateRangeTxn, setDateRangeTxn] = useState<any>(initialDateRange);
  const [dateRangeSettlement, setDateRangeSettlement] =
    useState<any>(initialDateRange);
  const [visitedTabs, setVisitedTabs] = useState<number[]>([]);
  const [kycData, setKycData] = useState<any>(null);

  const getProviderInfo = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/provider?location_id=${activeLocationId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setData(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProviderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateVisitedTabs = (tab: number, updateType: string) => {
    if (updateType === "add") {
      setVisitedTabs([...(new Set([...visitedTabs, tab]) as any)]);
    } else {
      setVisitedTabs(visitedTabs.filter((t) => t !== tab));
    }
  };

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {data ? (
            <Box m={-3}>
              <Box
                p={2}
                sx={{ backgroundColor: "#E6EEFF" }}
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Stack>
                  <Box display="flex" alignItems="center" gap={1} pb={2}>
                    <Link
                      color="secondary"
                      href="/merchants"
                      sx={{
                        textDecoration: "none",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      All Pharmacies
                    </Link>
                    <ChevronRight color="secondary" />
                    <Typography
                      variant="body2"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {data?.provider_name}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    alignItems="baseline"
                    gap={0.75}
                    pb={2}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "20px !important",
                        textTransform: "capitalize",
                      }}
                    >
                      {data?.provider_name}
                    </Typography>
                    <Stack direction="row" gap={1}>
                      <Chip
                        size="small"
                        label={data?.status}
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor:
                            data?.status === "active" ? "#C6F6D5" : "#C53030",
                          padding: "6px",
                          color: data?.status === "active" ? "#000" : "#fff",
                        }}
                      />
                      {data?.prime_store ? (
                        <img
                          width={100}
                          src={PrimeStoreIcon}
                          alt="prime store"
                        />
                      ) : null}
                    </Stack>
                  </Box>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    gap={data?.specialties.length ? 3 : 0}
                  >
                    <Typography variant="body2" color="secondary">
                      {data?.specialties?.join(" | ")}
                    </Typography>
                    {/* Allopathy Medicine &nbsp;|&nbsp; Ayurvedic Medicine */}
                    {data?.created_at_date ? (
                      <Box display="flex" alignItems="center" gap={0.5}>
                        <AccessTime fontSize="small" color="secondary" />
                        <Typography variant="body2" color="#33415C">
                          Created at {data?.created_at_date}
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                </Stack>
                {/* <Button
                  sx={{ textTransform: "capitalize" }}
                  color="primary"
                  variant="contained"
                >
                  Create Agreement
                </Button> */}
              </Box>
              <>
                {/* TODO:refactor tabs into a function */}
                <Tabs
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    sx={{ fontSize: "12px" }}
                    label="Summary"
                    {...a11yProps(0)}
                    onClick={() => navigate(`/merchants/${activeLocationId}`)}
                  />
                  <Tab
                    sx={{ fontSize: "12px" }}
                    label="MedPay QR"
                    {...a11yProps(1)}
                    onClick={() =>
                      navigate(`/merchants/${activeLocationId}?tab=2`)
                    }
                  />
                  <Tab
                    sx={{ fontSize: "12px" }}
                    label="Transactions"
                    {...a11yProps(1)}
                    onClick={() =>
                      navigate(`/merchants/${activeLocationId}?tab=3`)
                    }
                  />
                  <Tab
                    sx={{ fontSize: "12px" }}
                    label="Settlements"
                    {...a11yProps(1)}
                    onClick={() =>
                      navigate(`/merchants/${activeLocationId}?tab=4`)
                    }
                  />
                  <Tab
                    sx={{ fontSize: "12px" }}
                    label="Tickets"
                    {...a11yProps(1)}
                    onClick={() =>
                      navigate(`/merchants/${activeLocationId}?tab=5`)
                    }
                  />
                  <Tab
                    sx={{ fontSize: "12px" }}
                    label="MedPay Store"
                    {...a11yProps(1)}
                    onClick={() =>
                      navigate(`/merchants/${activeLocationId}?tab=6`)
                    }
                  />
                  {data?.medpay_store_enabled ? (
                    <Tab
                      sx={{ fontSize: "12px" }}
                      label="MedPay Prime"
                      {...a11yProps(1)}
                      onClick={() =>
                        navigate(`/merchants/${activeLocationId}?tab=7`)
                      }
                    />
                  ) : null}
                </Tabs>
                <Box px={2}>
                  <TabPanel value={value} index={0}>
                    <Summary
                      providerInfo={data}
                      setProviderInfo={setData}
                      kycData={kycData}
                      setKycData={setKycData}
                      visitedTabs={visitedTabs}
                      updateVisitedTabs={updateVisitedTabs}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <MedpayQr
                      data={medpayQrData}
                      setData={setMedpayQrData}
                      visitedTabs={visitedTabs}
                      updateVisitedTabs={updateVisitedTabs}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <MerchantTransactions
                      providerInfo={data}
                      dateRangeTxn={dateRangeTxn}
                      setDateRangeTxn={setDateRangeTxn}
                      data={txnData}
                      setData={setTxnData}
                      visitedTabs={visitedTabs}
                      updateVisitedTabs={updateVisitedTabs}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <MerchantSettlements
                      dateRangeSettlement={dateRangeSettlement}
                      setDateRangeSettlement={setDateRangeSettlement}
                      data={settlementData}
                      setData={setSettlementData}
                      visitedTabs={visitedTabs}
                      updateVisitedTabs={updateVisitedTabs}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <MerchantTickets
                      issuesData={issuesData}
                      setIssuesData={setIssuesData}
                      visitedTabs={visitedTabs}
                      updateVisitedTabs={updateVisitedTabs}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={5}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "60vh",
                      }}
                    >
                      {data?.store_link ? (
                        <Stack
                          sx={{
                            border: "1px solid #eeeeee",
                            borderRadius: 2,
                            maxWidth: "70%",
                          }}
                          direction="row"
                        >
                          <img
                            src="https://medpay-public.s3.ap-south-1.amazonaws.com/medpay_full_logo_1200x630.png"
                            alt="img"
                            width={210}
                            style={{
                              borderTopLeftRadius: 6,
                              borderBottomLeftRadius: 6,
                            }}
                          />
                          <Stack p={2} gap={1.5}>
                            <Typography variant="h6">
                              {data?.provider_name}, {data?.locality},{" "}
                              {data?.city} - MedPay
                            </Typography>
                            <Typography color="secondary" variant="body2">
                              {data?.store_link}
                            </Typography>
                            <Link
                              sx={{
                                textDecoration: "none",
                                border: "1px solid #0058ff",
                                px: 2,
                                py: 0.75,
                                width: "fit-content",
                                borderRadius: 25,
                                fontSize: "12px",
                              }}
                              href={data?.store_link}
                              target="_blank"
                            >
                              View Store
                            </Link>
                          </Stack>
                        </Stack>
                      ) : (
                        <Typography variant="h5">Coming Soon!</Typography>
                      )}
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={6}>
                    <MedpayPrime
                      providerInfo={data}
                      getProviderInfoFn={getProviderInfo}
                    />
                  </TabPanel>
                </Box>
              </>
            </Box>
          ) : null}
        </>
      )}
    </div>
  );
};

export default SingleMerchant;
