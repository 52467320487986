import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ActivatePrimeModal from "./activatePrimeModal";
import { parseAndFormateDate } from "../../../../utils/common";
import { Edit } from "@mui/icons-material";

const MedpayPrime = ({ providerInfo, getProviderInfoFn }: any) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      {providerInfo?.prime_store ? (
        <Box sx={{ background: "#E6EEFF", borderRadius: 2 }}>
          <Grid container>
            <Grid p={3} item xs={4}>
              <Typography variant="body2" color="secondary">
                Prime Coins(%)
              </Typography>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="h3">
                  {providerInfo?.prime_store_config?.coins}
                </Typography>
                <IconButton
                  onClick={() => setOpen(true)}
                  size="small"
                  color="primary"
                >
                  <Edit fontSize="small" />
                </IconButton>
              </Stack>
            </Grid>
            <Grid p={3} item xs={4}>
              <Typography variant="body2" color="secondary">
                Modified By
              </Typography>
              <Typography>
                {providerInfo?.prime_store_config?.modified_by}
              </Typography>
            </Grid>
            <Grid p={3} item xs={4}>
              <Typography variant="body2" color="secondary">
                Modified At
              </Typography>
              <Typography>
                {parseAndFormateDate(
                  providerInfo?.prime_store_config?.modified_on
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Button onClick={() => setOpen(true)} variant="contained">
              Activate Prime
            </Button>
          </Box>
        </>
      )}

      {open ? (
        <ActivatePrimeModal
          open={open}
          onClose={() => setOpen(false)}
          providerInfo={providerInfo}
          getProviderInfoFn={getProviderInfoFn}
        />
      ) : null}
    </div>
  );
};

export default MedpayPrime;
