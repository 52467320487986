import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { handleError, successToast } from "../../../../utils/common";
import IssueRowView from "../../../AppIssues2/issueRowView";
import IssueDetailModal2 from "../../../AppIssues2/issueDetailModal2";

const MerchantTickets = ({
  issuesData,
  setIssuesData,
  visitedTabs,
  updateVisitedTabs,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [showIssueDetailModal, setShowIssueDetailModal] = useState(false);
  const privateInstance = useAxiosInstance();
  const [activeIssueId, setActiveIssueId] = useState<any>(null);

  const params = useParams();

  const getIssuesList = async () => {
    let url = `${API_VERSION.V1}/issues?location_id=${params?.locationId}`;

    //reset local state on api call
    setIssuesData([]);

    setLoading(true);
    try {
      const result = await privateInstance.get(url);
      if (result.data?.status === API_RESULT.SUCCESS) {
        updateVisitedTabs(4, "add");
        //type open or date range
        setIssuesData(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!visitedTabs.includes(4)) {
      getIssuesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateIssuesData = async (issueId: string, payload: any) => {
    const res = await privateInstance.patch(
      `${API_VERSION.V1}/issues/${issueId}`,
      payload
    );

    if (res?.data?.status === API_RESULT.SUCCESS) {
      successToast("Updated Successfully");
      //update table UI Data;
      const itemIndex = issuesData.findIndex(
        (p: any) => p.issue_id === issueId
      );
      issuesData.splice(itemIndex, 1, {
        ...issuesData[itemIndex],
        ...payload,
      });
      setIssuesData([...issuesData]);
      //end
    }
  };

  return (
    <div>
      <>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {issuesData?.map((x: any, i: number) => {
              return (
                <IssueRowView
                  key={i}
                  tableRowData={[
                    x.issue_id,
                    x.location_id,
                    x.issue_type,
                    x.issue_sub_type,
                    x.follow_up_date,
                    x.assigned_team,
                    x.provider_name,
                    x.description.text,
                    x.issue_status,
                    x.created_at,
                  ]}
                  tableRowIndex={i}
                  updateIssuesData={updateIssuesData}
                  setShowIssueDetailModal={setShowIssueDetailModal}
                  setShowIssueHistoryModal={null}
                  setActiveIssueId={setActiveIssueId}
                  setShowProviderSettingsInfoModal={null}
                  setActiveLocationId={null}
                  isActionDisabled={x.issue_status === "resolved"}
                />
              );
            })}
          </>
        )}
      </>
      {showIssueDetailModal && (
        <>
          <IssueDetailModal2
            open={showIssueDetailModal}
            onClose={() => setShowIssueDetailModal(false)}
            data={issuesData?.find((x: any) => x.issue_id === activeIssueId)}
            activeIssueId={activeIssueId}
            getIssuesList={getIssuesList}
            updateIssuesData={updateIssuesData}
            setShowIssueHistoryModal={null} //to close parent modal
          />
        </>
      )}
    </div>
  );
};

export default MerchantTickets;
