import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import OrderAudits from "../../Settings/Orders/orderAudits";

const DeviceAuditModal = ({ open, onClose, activeDeviceId }: any) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
        Device Audit | {activeDeviceId}
      </CustomDialogTitle>
      <DialogContent>
        <OrderAudits
          oldAuditLogs={[]}
          orderId={activeDeviceId}
          showOrderStatus={false}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DeviceAuditModal;
