import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { format, parse } from "date-fns";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { infoToast, parseAndFormateDate } from "../../utils/common";
import IssueTypeCategoryView from "./issueTypeCategoryView";
import { medpayTeams } from "../../constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import IssueStatus from "./issueStatus";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HistoryIcon from "@mui/icons-material/History";

const IssueRowView = ({
  tableRowData,
  tableRowIndex,
  updateIssuesData,
  setShowIssueDetailModal,
  setShowIssueHistoryModal,
  setActiveIssueId,
  setShowProviderSettingsInfoModal,
  setActiveLocationId,
  isActionDisabled,
}: any) => {
  // TODO: refactor props
  const innerW = window.innerWidth;
  const theme = useTheme();

  const [
    issue_id,
    location_id,
    issue_type,
    issue_sub_type,
    follow_up_date,
    assigned_team,
    provider_name,
    descriptionMsg,
    issue_status,
    created_at,
  ] = tableRowData;

  const parsed_follow_up_date = follow_up_date
    ? parse(follow_up_date, "dd-MM-yyyy", new Date())
    : null;

  return (
    <TableRow
      key={issue_id}
      // hover
      style={{
        verticalAlign: "baseline",
        backgroundColor:
          issue_status !== "resolved" &&
          parse(follow_up_date, "dd-MM-yyyy", new Date()) <= new Date()
            ? "#FFE3B5"
            : "",
      }}
    >
      {innerW > theme.breakpoints.values.md ? (
        <>
          <TableCell
            sx={{
              width: {
                sm: "auto",
                md: setShowIssueHistoryModal ? "60%" : "80%",
              },
            }}
          >
            <Stack>
              <Stack direction="row" gap={3}>
                <Typography variant="h6">{issue_id}</Typography>
                <Box display="flex" alignItems="center" gap={0.5}>
                  <AccessTimeIcon color="secondary" sx={{ fontSize: "14px" }} />
                  <Typography
                    variant="body2"
                    color="secondary"
                    sx={{ fontSize: "12px !important" }}
                  >
                    {parseAndFormateDate(created_at)}
                  </Typography>
                </Box>
              </Stack>
              <Typography py={0.5} color="secondary" variant="body2">
                {descriptionMsg}
              </Typography>
              <Stack direction="row" alignItems="center" gap={1.5} mt={1}>
                <IssueTypeCategoryView
                  issueId={issue_id}
                  issueType={issue_type}
                  issueCategory={issue_sub_type || ""}
                  updateIssuesData={updateIssuesData}
                  index={tableRowIndex}
                  importedOn="listing"
                  isDisabled={isActionDisabled}
                />
                <FormControl
                  disabled={isActionDisabled}
                  size="small"
                  sx={{ minWidth: "fit-content" }}
                >
                  <Select
                    displayEmpty
                    labelId="team-label"
                    name="team"
                    id="team"
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return (
                          <span style={{ color: "#667185" }}>
                            Assigned Team
                          </span>
                        );
                      }
                      return selected?.split("_")?.join(" ");
                    }}
                    value={assigned_team || ""}
                    onChange={(e) => {
                      //update BE and prop data
                      updateIssuesData(issue_id, {
                        assigned_team: e.target.value,
                      });
                    }}
                    sx={{
                      borderRadius: "25px",
                      fontSize: "12px !important",
                      textTransform: "capitalize",
                    }}
                  >
                    {medpayTeams.map((x, i) => {
                      return (
                        <MenuItem disabled={i > 2} key={i} value={x.value}>
                          {x.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Stack direction="row" alignItems="center" gap={0.25}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled={isActionDisabled}
                      inputFormat="dd-MM-yyyy"
                      mask={"__-__-____"}
                      value={parsed_follow_up_date}
                      onChange={(newValue: Date | null) => {
                        if (newValue) {
                          //prevent calling api onclose
                          if (
                            format(newValue as Date, "dd-MM-yyyy") ===
                            follow_up_date
                          ) {
                            return false;
                          }
                          //update BE and prop data
                          updateIssuesData(issue_id, {
                            follow_up_date: format(
                              newValue as Date,
                              "dd-MM-yyyy"
                            ),
                          });
                        }
                      }}
                      minDate={
                        parsed_follow_up_date
                          ? parsed_follow_up_date
                          : new Date()
                      }
                      InputProps={{
                        sx: {
                          borderRadius: "25px !important",
                          minWidth: "fit-content",
                          maxWidth: "140px",
                          fontSize: "12px !important",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                          name="date"
                          autoComplete="off"
                          onKeyDown={(e: any) => {
                            e.preventDefault();
                          }}
                          InputLabelProps={{
                            sx: {
                              fontSize: "14px !important",
                              ml: "-4px",
                            },
                          }}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Follow Up",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {follow_up_date ? (
                    <IconButton
                      disabled={isActionDisabled}
                      color="error"
                      onClick={() => {
                        if (window.confirm("Are you sure?")) {
                          updateIssuesData(issue_id, {
                            follow_up_date: "",
                          });
                        }
                      }}
                    >
                      <Tooltip title="Remove Follow Up">
                        <ClearIcon />
                      </Tooltip>
                    </IconButton>
                  ) : null}
                </Stack>
              </Stack>
            </Stack>
          </TableCell>
          <TableCell sx={{ width: { sm: "auto", md: "20%" } }}>
            {setShowIssueHistoryModal ? (
              <>
                <Stack direction="row" alignItems="center" gap={0.5}>
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      color: "#0058ff",
                      cursor: "pointer",
                    }}
                    variant="body2"
                    onClick={() => {
                      setShowProviderSettingsInfoModal(true);
                      setActiveLocationId(location_id);
                    }}
                  >
                    {provider_name}
                  </Typography>
                  <CopyToClipboard
                    text={location_id}
                    onCopy={() => infoToast("Copied to clipboard")}
                  >
                    <Tooltip title="Copy Location ID">
                      <IconButton size="small">
                        <ContentCopyIcon
                          fontSize="small"
                          sx={{
                            width: "14px !important",
                            height: "14px !important",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </CopyToClipboard>
                </Stack>
                {isActionDisabled ? null : (
                  <Button
                    onClick={() => {
                      setShowIssueHistoryModal(true);
                      setActiveIssueId(issue_id);
                    }}
                    sx={{ mt: 0.5, textTransform: "capitalize" }}
                    size="small"
                    endIcon={<HistoryIcon />}
                  >
                    View History
                  </Button>
                )}
              </>
            ) : null}
          </TableCell>
          <TableCell sx={{ width: { sm: "auto", md: "12%" } }}>
            <IssueStatus value={issue_status} />
          </TableCell>
          <TableCell sx={{ width: { sm: "auto", md: "8%" } }}>
            <Tooltip
              title={
                issue_status?.toLowerCase() === "resolved"
                  ? "View More"
                  : "Update"
              }
            >
              <IconButton
                color="primary"
                aria-label="open"
                onClick={() => {
                  setShowIssueDetailModal(true);
                  setActiveIssueId(issue_id);
                }}
              >
                {issue_status?.toLowerCase() === "resolved" ? (
                  <VisibilityIcon />
                ) : (
                  <EditIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </TableCell>
        </>
      ) : (
        <TableCell>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5">{issue_id}</Typography>
            <Stack direction="row" alignItems="center" gap={1.5}>
              <IssueStatus value={issue_status} />
              <Tooltip
                title={
                  issue_status?.toLowerCase() === "resolved"
                    ? "View More"
                    : "Update"
                }
              >
                <IconButton
                  color="primary"
                  aria-label="open"
                  onClick={() => {
                    setShowIssueDetailModal(true);
                    setActiveIssueId(issue_id);
                  }}
                >
                  {issue_status?.toLowerCase() === "resolved" ? (
                    <VisibilityIcon />
                  ) : (
                    <EditIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography
              sx={{
                textTransform: "capitalize",
                fontSize: "14px",
                color: "#0058ff",
                cursor: "pointer",
              }}
              variant="body1"
              onClick={() => {
                setShowProviderSettingsInfoModal(true);
                setActiveLocationId(location_id);
              }}
            >
              {provider_name}
            </Typography>
            <CopyToClipboard
              text={location_id}
              onCopy={() => infoToast("Copied to clipboard")}
            >
              <Tooltip title="Copy Location ID">
                <IconButton size="small">
                  <ContentCopyIcon
                    fontSize="small"
                    sx={{
                      width: "14px !important",
                      height: "14px !important",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Stack>
          <Typography
            py={0.5}
            color="secondary"
            variant="body2"
            sx={{ fontSize: "12px" }}
          >
            {descriptionMsg}
          </Typography>
          <Stack
            pt={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center" gap={0.5}>
              <AccessTimeIcon color="secondary" sx={{ fontSize: "14px" }} />
              <Typography
                variant="body2"
                color="secondary"
                sx={{ fontSize: "12px !important" }}
              >
                {parseAndFormateDate(created_at)}
              </Typography>
            </Box>
            {/* <Box display="flex" alignItems="center" gap={0.5}>
            <FmdGoodOutlinedIcon fontSize="small" color="primary" />
            <Typography color="primary">3.2kms</Typography>
          </Box> */}
          </Stack>
        </TableCell>
      )}
    </TableRow>
  );
};

export default IssueRowView;
